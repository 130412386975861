<template>
  <div>
    <van-pull-refresh v-model="isPulling" @refresh="onRefresh">
      <swiper
        v-if="banners.length > 0"
        class="banner"
        :options="swiperOption"
        ref="banner"
      >
        <swiper-slide v-for="item in banners" :key="item.banner_id">
          <img
            :src="item.image"
            @click="toBannerDetail(item)"
            class="slide-banner"
          />
        </swiper-slide>
        <!-- <div class="swiper-pagination"
           slot="pagination"></div> -->
      </swiper>
      <div class="news-wrap">
        <van-cell
          :title="sepTitle"
          is-link
          v-if="sepTitle.length > 0"
          @click="showMore"
        />
        <van-list class="news-list">
          <div
            class="news-item"
            v-for="item in newsList"
            :key="item.articel_id"
            @click="toNewsDetail(item)"
          >
            <label>{{ item.title }}</label>
            <img :src="item.cover" mode="aspectFill" />
          </div>
        </van-list>
      </div>
    </van-pull-refresh>
  </div>
</template>

<script>
import { fetchBanner, fetchList, fetchTypes } from "@/network/API";
import { Cell, List, PullRefresh, Button } from "vant";
export default {
  components: {
    List,
    PullRefresh,
    Button,
  },
  data() {
    return {
      optionData: {},
      newsList: [],
      banners: [],
      title: "",
      code: "",
      sepTitle: "",
      typeId: "",
      subTypeId: "",
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },

      page: 1,
      isPulling: false,
      isMore: false,
      finished: true,
    };
  },
  mounted() {
    // this.$route.meta.title = this.title;
  },
  created() {
    if (this.$route.query.tid) {
      this.typeId = this.$route.query.tid;
    }
    if (this.$route.query.name) {
      this.title = this.$route.query.name;
    }
    if (this.$route.query.code) {
      this.code = this.$route.query.code;
    }
    this.$store.commit("updateTitle", {
      pushed: true,
    });
    this.$store.commit("updateNavTitle", {
      navTitle: this.title,
    });
    this.getSubType();
    this.getBanners();
  },
  methods: {
    showMore() {
      this.$router.push({
        path: "/nlist",
        query: {
          name: this.title,
          tid: this.typeId,
          sid: this.subTypeId,
        },
      });
    },
    loadMore() {
      // this.page += 1
      // this.getArticleList(true)
    },
    onRefresh() {
      this.finished = false;
      this.page = 1;
      this.getArticleList();
    },
    async getSubType() {
      let data = await fetchTypes({
        type_id: this.typeId,
      });
      if (data.length >= 1) {
        let info = data[0];
        this.sepTitle = info.type_name;
        this.subTypeId = info.type_id;
        this.getArticleList();
      }
    },
    async getBanners() {
      this.banners = await fetchBanner({
        type_id: this.typeId,
      });
      if (this.banners.length <= 1) {
        this.swiperOption.loop = false;
      }
    },
    async getArticleList(more = false) {
      this.isLoading = true;
      let data = await fetchList({
        page_size: 5,
        page: this.page,
        type_id: this.typeId,
        sub_type_id: this.subTypeId,
        is_hot: 1,
      });
      this.isPulling = false;
      this.isMore = false;
      if (more) {
        this.newsList = [...this.newsList, ...data.list];
      } else {
        this.newsList = data.list;
      }
      if (this.newsList.length >= parseInt(data.total)) {
        this.finished = true;
      } else {
        this.finished = false;
      }
    },
    bannerClick(e) {
      // console.log(e);
    },
    toBannerDetail(item) {
      if (item.link_to.length == 0) {
        return;
      }
      this.$router.push({
        name: "Detail",
        params: {
          id: item.link_to,
        },
      });
    },
    toNewsDetail(item) {
      this.$router.push({
        name: "Detail",
        params: {
          id: item.article_id,
        },
      });
    },
  },
};
</script>

<style></style>
